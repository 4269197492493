export const useHandleError = (error: Error, url?: string) => {
  const runtimeConfig = useRuntimeConfig()

  if (runtimeConfig.public.enableBugSnag) {
    useBugsnag().notify(new Error(error.message), (e: any) => {
      e.severity = 'error'
      e.context = `${runtimeConfig.public.communityName}: ${error.name} | ${
        url ?? useRoute()?.fullPath
      }`
      e.addMetadata('debug info', error)
    })
  } else {
    console.error(error.message)
  }
}
